// Invoke the IIFE to start scanning for workflows.
(function () {
    let workflows = document.body.querySelectorAll('.fragment');
    let workflow_parsed = Array.from(workflows).map(item => item.id.toUpperCase().split('-').join('.'));
    let title = 'CommsAPI Debugbar';
    let mini = minifiedState();

    // Add the debugbar into the HTML
    appendHtml(document.body, `
        <div id="comms-debugbar">
            <div id="cdb__title">
                <span>
                    ${title}
                </span>
            </div>
            <div id="cdb__workflows">
                <span id="check_workflows">Workflows [Onload]</span>
                <span class="cdb__tags onload">${workflows.length}</span>
                <div id="cdb__workflows-list">
                    <ul>
                        ${workflow_parsed.map(
                            item => `<li>${item}</li>`
                        ).join('')}
                    </ul>
                </div>
            </div>
            <div id="cdb__workflow-requests">
                Requests
                <span class="cdb__tags reqs">0</span>
            </div>
            <div id="cdb__dashboard-link">
                <a href="/stratum/comms/dashboard" target="_blank">Comms Dashboard</a>
            </div>
            <div id="cdb__wrap-link">
                <a href="${wrap_link}" target="_blank">Wrap Management</a>
            </div>
            <div id="cdb__minify">
                <span>Close</span>
            </div>
        </div>
        <div id="comms-debugbar__minified">
            API
        </div>
    `);

    mini.setEventListeners();
    if(localStorage.getItem(mini.alias) === 'Closed') {
        mini.toggle('Close');
    }

    // Handle the workflow details
    if (workflows.length > 0) {
        window.addEventListener('commsAPI_XHR', () => {
            document.querySelector('#cdb__workflow-requests .reqs').innerHTML = window.comms_requests.length;
        })
        document.getElementById('cdb__workflow-requests').onclick = () => {
            renderWorkflowRequests();
        };
        document.getElementById('cdb__workflows').onclick = () => {
            let display = document.getElementById('cdb__workflows-list').style.display;
            if (display === '' || display === 'none') {
                document.getElementById('cdb__workflows-list').style.display = 'block';
            } else {
                document.getElementById('cdb__workflows-list').style.display = 'none';
            }
        };
    }
})();

/**
 * Handle the minified state of the debugbar
 *
 * @param {string} status Open,Close
 */
function minifiedState(status)
{
    const alias = 'COMMSAPI_DEBUG_STATUS';
    let toggleStatus = status => {
        if (status === 'Open') {
            document.getElementById('comms-debugbar').style.display = 'block';
            document.getElementById('comms-debugbar__minified').style.display = 'none';
            localStorage.setItem(alias, 'Open');
        } else {
            document.getElementById('comms-debugbar').style.display = 'none';
            document.getElementById('comms-debugbar__minified').style.display = 'block';
            localStorage.setItem(alias, 'Closed');
        }
    }
    return {
        alias: alias,
        toggle: toggleStatus,
        setEventListeners: () => {
            document.getElementById('cdb__minify').onclick = () => {
                toggleStatus('Close');
            }
            document.getElementById('comms-debugbar__minified').onclick = () => {
                toggleStatus('Open');
            }
        }
    }
}

/**
 * This will create a popup containing all the workflow requests captured by out XHR capture script.
 */
function renderWorkflowRequests() {
    try {
        let timings = workflow.filtered_contents.timings.api.overall;
    } catch (e) {
        let timings = undefined;
    }
    try {
        let fingerprint = workflow.filtered_contents.fingerprint;
    } catch (e) {
        let fingerprint = undefined;
    }
    appendHtml(document.body, `
        <div id="cdb__popup">
            <div class="cdb__popup-header">
                <h2>Workflow Requests</h2>
                <span id="cdb__popup-close">
                    X
                </span>
            </div>
            <div id="cdb__popup-body">
                ${window.comms_requests.map((workflow, index) => {
                    let fingerprint, timings = undefined;
                    if (hasOwnProperty.call(workflow.filtered_contents, 'timings')) {
                        if (hasOwnProperty.call(workflow.filtered_contents.timings, 'api')) {
                            timings = workflow.filtered_contents.timings.api.overall;
                        }
                    } else {
                        timings = 'N/A ';
                    }
                    if (typeof workflow.filtered_contents.fingerprint !== 'undefined') {
                         fingerprint = workflow.filtered_contents.fingerprint;
                    }
                    return `<div class="cdb__workflow-item">
                        <p>
                            <b>${workflow.time}</b> ${timings !== undefined ? `(Duration: ${timings}ms)` : ``} |
                            ${workflow.args[0]} --> ${workflow.url}
                            ${fingerprint !== undefined ?
                                `<span class="fingerprint__tag">${fingerprint}</span>
                                <br/><hr/>
                                <button data-workflowReq="${index}" data-action="diff" class="cdb__workflow-action">
                                    View Diff
                                </button>
                                <button data-workflowReq="${index}" data-action="raw" class="cdb__workflow-action">
                                    View Raw
                                </button>` : `<br/><hr/>`
                            }
                            <button data-workflowReq="${index}" data-action="filtered" class="cdb__workflow-action">
                                View Filtered
                            </button>
                            ${workflow.args[0] === 'POST' ? `
                            <button data-workflowReq="${index}" data-action="post" class="cdb__workflow-action">
                                Post Data
                            </button>` : ``}
                            <button data-workflowReq="${index}" data-action="headers" class="cdb__workflow-action">
                                Headers
                            </button>
                        </p>
                    </div>
                `}).join('')}
            </div>
        </div>
    `);
    document.getElementById('cdb__popup-close').onclick = () => {
        document.getElementById('cdb__popup').remove();
    }
    createButtonEvents();
}

/**
 * Create popup containing a diff between the raw and filtered JSON
 * todo: Look into comparison algorithm as body is filtered in such a way it think its all different.
 *
 * @param {JSON} filtered
 * @param {JSON} raw
 */
function renderJSONDiff(filtered, raw) {
    var delta = jsondiffpatch.create({
        objectHash: function(obj) {
            return obj._id || obj.id;
        },
        arrays: {
            detectMove: true,
            includeValueOnMove: false
        },
        textDiff: {
            minLength: 60
        },
        propertyFilter: function(name, context) {
          return name.slice(0, 1) !== '$';
        },
        cloneDiffValues: false
    }).diff(raw, filtered);
    return jsondiffpatch.formatters.html.format(delta, raw);
}

/**
 * lightweight method to pretty print JSON object.
 *
 * @param {string} json
 */
function renderJSON(json, highlight) {
    if(highlight !== false) {
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }
    return `<pre>${json}</pre>`;
}

/**
 * Create a popup to display the JSON data
 *
 * @param {string} data
 * @param {string} title
 */
function createJSONPopup(data, title) {
    appendHtml(document.body, `
        <div id="cdb__json-popup">
            <div class="cdb__popup-header">
                <h2>${title}</h2>
                <span id="cdb__json-popup-close">
                    X
                </span>
            </div>
            <div id="cdb__popup-body">
                <div id="cdb__raw-json">
                    ${data}
                </div>
            </div>
        </div>
    `);
    document.getElementById('cdb__json-popup-close').onclick = () => {
        document.getElementById('cdb__json-popup').remove();
    }
}

/**
 * Fetch the fingerprint from Redis.
 *
 * @param {sting} fingerprint
 * @param {function} callback
 */
function fetchFingerprint(fingerprint, callback) {
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (xhttp.readyState == XMLHttpRequest.DONE) {
            if (xhttp.status == 200) {
                callback(xhttp.responseText);
            }
            else {
                console.error('Could not retrieve raw contents of fingerprint');
            }
        }
    };
    xhttp.open("GET", "/comms/debug/fingerprint/" + fingerprint, true);
    xhttp.send();
}

/**
 * Create popup events for each of the buttons on a workflow request.
 */
function createButtonEvents() {
    Array.from(document.getElementsByClassName('cdb__workflow-item')).forEach(element => {
        element.onclick = (event) => {
            let req = event.target.dataset.workflowreq;
            let action = event.target.dataset.action;
            let request = window.comms_requests[req];
            let filtered_contents = request['filtered_contents'];
            let raw_contents = null;
            switch(action) {
                case 'diff':
                    let originalText = event.target.innerText;
                    event.target.innerText = 'Loading...';
                    fetchFingerprint(filtered_contents.fingerprint, contents => {
                        event.target.innerText = originalText;
                        createJSONPopup(
                            renderJSONDiff(
                                JSON.parse(JSON.stringify(filtered_contents)),
                                JSON.parse(contents)
                            ),
                            'PHP vs Javascript'
                        )
                    })
                    break;
                case 'raw':
                    originalText = event.target.innerText;
                    event.target.innerText = 'Loading...';
                    fetchFingerprint(filtered_contents.fingerprint, contents => {
                        event.target.innerText = originalText;
                        createJSONPopup(
                            renderJSON(
                                JSON.stringify(JSON.parse(contents), null, 2)
                            ),
                            'Raw content (PHP)'
                        );
                    })
                    break;
                case 'filtered':
                    createJSONPopup(
                        renderJSON(
                            JSON.stringify(filtered_contents, null, 2),
                        ),
                        'Filter content (Javascript)'
                    );
                    break;
                case 'post':
                    createJSONPopup(
                        renderJSON(
                            JSON.stringify(JSON.parse(request.pdata), null, 2),
                        ),
                        'Post data'
                    );
                    break;
                case 'headers':
                    createJSONPopup(
                        renderJSON(JSON.stringify(JSON.parse(request.headers), null, 2)),
                        'Request Headers'
                    );
                    break;
            }
        }
    });
}

/**
 * Simple helper to allow us to append some HTML.
 *
 * @param {DOM.element} el
 * @param {string} str
 */
function appendHtml(el, str) {
    var div = document.createElement('div');
    div.innerHTML = str;
    while (div.children.length > 0) {
        el.appendChild(div.children[0]);
    }
}